
















import {Vue, Component, Prop} from 'vue-property-decorator';

import FormField from '@/shared/resources/components/forms/FormField.vue';
import OnlineOrderCoreModel from '@/modules/online-orders/models/OnlineOrderCoreModel';
import RadioField from '@/shared/resources/components/forms/fields/RadioField.vue';
import { PaymentInterface } from '@/modules/payments/interfaces/PaymentsInterface';
import PaymentsRadioField from '@/app/modules/payments/components/fields/PaymentsRadioField.vue';
import { Validation } from 'vuelidate';
import Payments from '@/shared/lib/support/Payments';
import Alert from '@/shared/resources/components/Alert.vue';
import { PaymentsEnum } from '@/modules/payments/enums/PaymentsEnum';
import { bankDataAccountNumber } from '@/shared/lib/config/generalConfig';
import AccountNumberAlert from '@/app/components/AccountNumberAlert.vue';

@Component({
  components: {
    AccountNumberAlert,
    Alert,
    PaymentsRadioField,
    RadioField,
    FormField,
  },
})
export default class PaymentFieldset extends Vue {
  /**
   * Props
   */
  @Prop() private order!: OnlineOrderCoreModel;
  @Prop() private validation!: Validation;

  /**
   * Display getters
   */
  private get displayBankwireAlert(): boolean {
    return this.order.payment === PaymentsEnum.BANKWIRE;
  }

  /**
   * Getters
   */
  private get payments(): PaymentInterface[] {
    return Payments.getSelectablePaymentsList();
  }
}
